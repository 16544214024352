import Layout from "../components/Layout"
import React, { useEffect } from "react"
import useGroqResults from "../hooks/useGroqResults"
import Page from "../components/Page"
import { trackPageType } from "../lib/tracking/tracking"
import SEO from "../components/SEO"
import { gatsbyTemplateTypes } from "../lib/propTypes"

const PageTemplate = ({ location, pageContext: { query } }) => {
  const [page] = useGroqResults(query)

  useEffect(() => {
    trackPageType(page.path.current === "/" ? "home" : "pages")
  }, [page.path.current])

  return (
    <Layout title={page.title}>
      <SEO seo={page.seo} pathname={location.pathname} />
      <Page key={page._id} page={page} />
    </Layout>
  )
}

PageTemplate.propTypes = gatsbyTemplateTypes

export default PageTemplate
